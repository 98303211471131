import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "locationValidationMessage",
    "addressOption",
    "pickUpPointsOption",
    "schoolOption",
    "scheduleSlotButton",

    "bookingLocationPickupHomeWorkAddress",
    "bookingLocationPickupHomeWorkZipcode",

    "pickUpLocation",
    "dropOffLocation",

    "addressLine1",
    "zipcode",
  ];

  connect() {}

  async schedulingSkipped(event) {
    event.preventDefault();
    this.locationValidationMessageTarget.hidden = true;

    let errors = this.validateForm();
    if (errors.length > 0) {
      this.locationValidationMessageTarget.hidden = false;
      this.locationValidationMessageTarget.innerHTML = errors.join("<br>");
      return 
    }

    let addressOption = this.addressOptionTarget;

    this.addressLine1Target.value =
    this.bookingLocationPickupHomeWorkAddressTarget.value;
    this.zipcodeTarget.value =
    this.bookingLocationPickupHomeWorkZipcodeTarget.value;

    if (addressOption.checked) {
      this.findSlotsForAddressForSkip()
    } else if (this.pickUpPointsOptionTarget.checked || this.schoolOptionTarget.checked) {
      this.element.dispatchEvent(
        new CustomEvent("schedulingSkipped", {
          detail: {
            formName: "driving-school-admin-booking-form-v2-location-details",
          },
        })
      );
    } else {
      alert("No option selected");
    }
  }

  updateLocationDetails(event) {
    const locationData = event.detail.locationData;

    const bookingLocationPickupHomeWorkZipcode = document.getElementById(
      "booking_location_pickup_home_work_zipcode_value"
    );
    if (bookingLocationPickupHomeWorkZipcode) {
      bookingLocationPickupHomeWorkZipcode.value = locationData.pick_up_zipcode;
    }
    const bookingLocationPickupAddressLine1 = document.getElementById(
      "booking_location_pickup_home_work_address_value"
    );
    if (bookingLocationPickupAddressLine1) {
      bookingLocationPickupAddressLine1.value =
        locationData.pick_up_address_line_1;
    }

    if (locationData.is_address_in_zone == false) {
      this.locationValidationMessageTarget.hidden = false;

      let button = this.scheduleSlotButtonTarget;
      let spinner = button.querySelector(".Spinner");
      spinner.classList.add("u-hidden");
      spinner.classList.remove("u-inlineBlock");
      button.disabled = false;
    }
  }

  pickUpDropOffSelected(elementId, fieldToUpdate) {
    // Query all elements with IDs that start with elementId
    const pickUpPoints = document.querySelectorAll(`[id^="${elementId}-"]`);

    // Initialize id and idNumber variables
    let id = "";
    let idNumber = 0;

    // Loop through the elements to find the one that's selected
    for (let i = 0; i < pickUpPoints.length; i++) {
      if (!pickUpPoints[i].classList.contains("Panel--subtle")) {
        id = pickUpPoints[i].id;
        idNumber = id.replace(`${elementId}-`, "");
        break; // Exit the loop if the selected element is found
      }
    }

    // If idNumber is 0, exit the function
    if (idNumber == 0) {
      return;
    }

    // Update the value of the field specified by fieldToUpdate
    const field = document.getElementById(fieldToUpdate);
    if (field) {
      field.value = idNumber;
    }
  }

  getLocationData() {
    const office_pickup_category = document.querySelector(
      'input[name="driving_school_booking[office_pickup_category]"]:checked'
    ).value;
    let office_drop_off_category = document.querySelector(
      'input[name="driving_school_booking[office_drop_off_category]"]:checked'
    ).value;
    const same_as_pick_up_location = document.getElementById(
      "sameAsPickUpLocation"
    ).checked;

    let pick_up_address_line_1 = "";
    let pick_up_zipcode = "";
    let pick_up_school_id = "";
    let pick_up_point_id = "";

    let drop_off_address_line_1 = "";
    let drop_off_zipcode = "";
    let drop_off_school_id = "";
    let drop_off_point_id = "";

    // if pickup is home/work get the values for booking_location_pickup_home_work_address and zipcode
    if (office_pickup_category == "Address") {
      pick_up_address_line_1 = document.getElementById(
        "booking_location_pickup_home_work_address_value"
      ).value;
      pick_up_zipcode = document.getElementById(
        "booking_location_pickup_home_work_zipcode_value"
      ).value;
    }
    // if pick up is school, get the ID for the school pick_up_high_school_id
    if (office_pickup_category == "School") {
      pick_up_school_id = document.getElementById(
        "driving_school_booking_pick_up_high_school_id"
      ).value;
    }
    if (office_pickup_category == "Pick-up points") {
      pick_up_point_id = document.getElementById(
        "driving_school_booking_pick_up_pick_up_point_id"
      ).value;
    }

    if (same_as_pick_up_location == true) {
      // use same values as pick up
      office_drop_off_category = office_pickup_category;
      if (office_pickup_category == "Pick-up points") {
        office_drop_off_category = "Drop-off points";
      }

      drop_off_address_line_1 = pick_up_address_line_1;
      drop_off_zipcode = pick_up_zipcode;
      drop_off_school_id = pick_up_school_id;
      drop_off_point_id = pick_up_point_id;
    } else {
      if (office_drop_off_category == "Address") {
        drop_off_address_line_1 = document.getElementById(
          "booking_location_drop_off_home_work_address_value"
        ).value;
        drop_off_zipcode = document.getElementById(
          "booking_location_drop_off_home_work_zipcode_value"
        ).value;
      }
      // if pick up is school, get the ID for the school pick_up_high_school_id
      if (office_drop_off_category == "School") {
        drop_off_school_id = document.getElementById(
          "driving_school_booking_drop_off_high_school_id"
        ).value;
      }
      if (office_drop_off_category == "Drop-off points") {
        drop_off_point_id = document.getElementById(
          "driving_school_booking_drop_off_drop_off_point_id"
        ).value;
      }
    }

    return {
      office_pickup_category,
      office_drop_off_category,
      pick_up_address_line_1,
      pick_up_zipcode,
      pick_up_school_id,
      pick_up_point_id,
      drop_off_address_line_1,
      drop_off_zipcode,
      drop_off_school_id,
      drop_off_point_id,
    };
  }

  booking_pickup_home_work_clicked(event) {
    document.getElementById("booking-location-pickup-home-work").hidden = false;
    document.getElementById("booking-location-pickup-points").hidden = true;
    document.getElementById("booking-location-pickup-schools").hidden = true;
  }

  booking_pickup_points_clicked(event) {
    document.getElementById("booking-location-pickup-home-work").hidden = true;
    document.getElementById("booking-location-pickup-points").hidden = false;
    document.getElementById("booking-location-pickup-schools").hidden = true;
  }

  booking_pickup_schools_clicked(event) {
    document.getElementById("booking-location-pickup-schools").hidden = false;
    document.getElementById("booking-location-pickup-home-work").hidden = true;
    document.getElementById("booking-location-pickup-points").hidden = true;
  }

  booking_drop_off_home_work_clicked(event) {
    document.getElementById("booking-location-drop-off-points").hidden = true;
    document.getElementById("booking-location-drop-off-schools").hidden = true;
    document.getElementById(
      "booking-location-drop-off-home-work"
    ).hidden = false;
  }

  booking_drop_off_points_clicked(event) {
    document.getElementById(
      "booking-location-drop-off-home-work"
    ).hidden = true;
    document.getElementById("booking-location-drop-off-schools").hidden = true;
    document.getElementById("booking-location-drop-off-points").hidden = false;
  }

  booking_drop_off_schools_clicked(event) {
    document.getElementById(
      "booking-location-drop-off-home-work"
    ).hidden = true;
    document.getElementById("booking-location-drop-off-points").hidden = true;
    document.getElementById("booking-location-drop-off-schools").hidden = false;
  }

  toggle_drop_off_location(event) {
    // check if sameAsPickUpLocation is checked
    if (document.getElementById("sameAsPickUpLocation").checked) {
      document.getElementById("full-drop-off-form").hidden = true;

      const address_line_1 = document.getElementById(
        "address_line_1_value"
      ).value;
      const zipcode = document.getElementById("zipcode_value").value;
      document.getElementById(
        "booking_location_drop_off_home_work_address_value"
      ).value = address_line_1;
      document.getElementById(
        "booking_location_drop_off_home_work_zipcode_value"
      ).value = zipcode;
    } else {
      document.getElementById("full-drop-off-form").hidden = false;

      document.getElementById(
        "booking_location_drop_off_home_work_address_value"
      ).value = "";
      document.getElementById(
        "booking_location_drop_off_home_work_zipcode_value"
      ).value = "";
    }
  }

  toggle_drop_off_location_lesson(event) {
    // check if sameAsPickUpLocation is checked
    if (document.getElementById("sameAsPickUpLocation").checked) {
      document.getElementById("full-drop-off-form").hidden = true;
      document.getElementById("sameAsPickUpLocation").value = "true";
    } else {
      document.getElementById("full-drop-off-form").hidden = false;
      document.getElementById("sameAsPickUpLocation").value = "false";
    }
  }

  async checkValueInStream(streamText) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(streamText, "text/html");
    const turboStream = doc.querySelector(
      'turbo-stream[target="locations-list-is-in-zone"]'
    );

    console.log("Turbo Stream found:", turboStream !== null);

    if (turboStream) {
      const template = turboStream.querySelector("template");

      if (template) {
        const templateContent = template.content;
        const div = templateContent.querySelector(
          'div[id="locations-list-is-address-in-zone-div"]'
        );

        console.log("Div found in template:", div !== null);

        if (div) {
          const textContent = div.textContent.trim();
          console.log("Text content:", textContent);
          return textContent === "true";
        }
      }
    }

    return false;
  }

  // COULD BE DELETED????
  get_fetch_locations_url(
    zipcode,
    address_line_1,
    driving_school_token,
    extraParams
  ) {
    alert("is used?");
    if (extraParams == null) {
      return `/fetch_locations?zipcode=${zipcode}&address=${address_line_1}&driving_school_token=${driving_school_token}`;
    } else {
      return `/fetch_locations?zipcode=${zipcode}&address=${address_line_1}&driving_school_token=${driving_school_token}&${extraParams}`;
    }
  }

  async findSlotsPressed(event) {
    event.preventDefault();

    const errors = this.validateForm();
    const validationBlock = document.getElementById(
      "validation-locations-block"
    );
    const validationMessage = document.getElementById(
      "validation-message-locations-text"
    );

    if (errors.length > 0) {
      validationBlock.hidden = false;
      validationMessage.innerHTML = errors.join("<br>");
      validationMessage.hidden = false;
      return;
    }

    validationBlock.hidden = true;
    validationMessage.hidden = true;

    let button = this.scheduleSlotButtonTarget;
    let spinner = button.querySelector(".Spinner");
    spinner.classList.remove("u-hidden");
    spinner.classList.add("u-inlineBlock");
    button.disabled = true;

    let addressOption = this.addressOptionTarget;

    if (addressOption.checked) {
      this.addressLine1Target.value =
        this.bookingLocationPickupHomeWorkAddressTarget.value;
      this.zipcodeTarget.value =
        this.bookingLocationPickupHomeWorkZipcodeTarget.value;

      this.findSlotsForAddress();
    } else if (this.pickUpPointsOptionTarget.checked) {
      this.findSlotsForPickUpPoints();
    } else if (this.schoolOptionTarget.checked) {
      this.findSlotsForSchool();
    } else {
      alert("No option selected");
    }
  }

  async findSlotsForPickUpPoints() {
    this.pickUpDropOffSelected(
      "pick-up-points",
      "driving_school_booking_pick_up_pick_up_point_id"
    );
    this.pickUpDropOffSelected(
      "drop-off-points",
      "driving_school_booking_drop_off_drop_off_point_id"
    );

    const locationData = this.getLocationData();
    this.checkZonesForPickUpPointsAndDropOffPoints(locationData);
  }

  async findSlotsForSchool() {
    this.pickUpDropOffSelected(
      "school-pick-up-points",
      "driving_school_booking_pick_up_high_school_id"
    );
    this.pickUpDropOffSelected(
      "school-drop-off-points",
      "driving_school_booking_drop_off_high_school_id"
    );

    const locationData = this.getLocationData();
    this.checkZonesForPickUpPointsAndDropOffPoints(locationData);
  }

  async findSlotsForAddressForSkip() {

    let address_line_1 = this.bookingLocationPickupHomeWorkAddressTarget.value;
    let zipcode = this.bookingLocationPickupHomeWorkZipcodeTarget.value;

    const driving_school_token = document
      .getElementById("us-school-token")
      .innerHTML.trim();

    const url = `/fetch_locations?zipcode=${zipcode}&address=${address_line_1}&driving_school_token=${driving_school_token}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json", // Expecting JSON response
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      this.element.dispatchEvent(
        new CustomEvent("schedulingSkipped", {
          detail: {
            formName: "driving-school-admin-booking-form-v2-location-details",
          },
        })
      );
    } else {
      // Handle error
      try {
        let errorJson = await response.json();
        if (errorJson.error != 'Invalid address') {
          this.locationValidationMessageTarget.innerHTML = "Server not responding correctly, please check your internet connection."
        }
      } catch(e) {
        this.locationValidationMessageTarget.innerHTML = "Server not responding correctly, please check your internet connection."
      }
    }
  }

  async findSlotsForAddress() {
    let address_line_1 = this.bookingLocationPickupHomeWorkAddressTarget.value;
    let zipcode = this.bookingLocationPickupHomeWorkZipcodeTarget.value;

    const driving_school_token = document
      .getElementById("us-school-token")
      .innerHTML.trim();

    const url = `/fetch_locations?zipcode=${zipcode}&address=${address_line_1}&driving_school_token=${driving_school_token}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json", // Expecting JSON response
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      const data = await response.json(); // Parse the JSON response

      // Check if address is in zone
      if (data.is_address_in_zone) {
        let data_to_return = {
          pick_up_address_line_1: address_line_1,
          pick_up_zipcode: zipcode,
          drop_off_address_line_1: address_line_1,
          drop_off_zipcode: zipcode,
          is_address_in_zone: data.is_address_in_zone,
          zone_name: data.matching_zone.name,
          zone_color: data.matching_zone.color,
          zone_id: data.matching_zone.id,
        };

        this.element.dispatchEvent(
          new CustomEvent("addressFound", {
            detail: {
              formName: "driving-school-admin-booking-form-v2-address-details",
              locationData: data_to_return,
            },
          })
        );

        let button = this.scheduleSlotButtonTarget;
        let spinner = button.querySelector(".Spinner");
        spinner.classList.add("u-hidden");
        spinner.classList.remove("u-inlineBlock");
        button.disabled = false;

        window.scrollTo(0, 0);
      } else {
        let data_to_return = {
          pick_up_address_line_1: address_line_1,
          pick_up_zipcode: zipcode,
          drop_off_address_line_1: address_line_1,
          drop_off_zipcode: zipcode,
          is_address_in_zone: data.is_address_in_zone,
          zone_name: "Outside of zone",
          zone_color: "#000000",
          zone_id: 0,
        };

        // Address is not in zone, so now check if locations or schools are empty
        if (data.locations.length === 0 && data.schools.length === 0) {
          alert("No locations or schools available");
          // Handle the logic for when both are empty
        } else {
          const url = `/fetch_locations?zipcode=${zipcode}&address=${address_line_1}&driving_school_token=${driving_school_token}`;
          const response = await fetch(url, {
            method: "GET",
            headers: {
              Accept: "text/vnd.turbo-stream.html",
            },
          });

          if (response.ok) {
            const stream = await response.text();
            this.locationValidationMessageTarget.hidden = true;
            Turbo.renderStreamMessage(stream);

            // find us-booking-form-admin-pick-up-and-slots and show it
            const bookingFormAdminPickUpAndSlots = document.getElementById(
              "us-booking-form-admin-pick-up-and-slots"
            );
            if (bookingFormAdminPickUpAndSlots) {
              bookingFormAdminPickUpAndSlots.hidden = false;
            }

            // find us-booking-form-admin-location and hide it
            const bookingFormAdminLocation = document.getElementById(
              "us-booking-form-admin-location"
            );
            if (bookingFormAdminLocation) {
              bookingFormAdminLocation.hidden = true;
            }

            this.element.dispatchEvent(
              new CustomEvent("addressOutsideZone", {
                detail: {
                  formName:
                    "driving-school-admin-booking-form-v2-address-details",
                  locationData: data_to_return,
                },
              })
            );
          } else {
            // Handle error
            // validationMessage.innerHTML =
            this.locationValidationMessageTarget.hidden = false;
            //   "The address you entered is not valid or is not covered by this school. Please try again.";
            // validationMessage.hidden = false;

            let button = this.scheduleSlotButtonTarget;
            let spinner = button.querySelector(".Spinner");
            spinner.classList.add("u-hidden");
            spinner.classList.remove("u-inlineBlock");
            button.disabled = false;
          }
        }
      }
    } else {
      // Handle error

      try {
        let errorJson = await response.json();
        if (errorJson.error != 'Invalid address') {
          this.locationValidationMessageTarget.innerHTML = "Server not responding correctly, please check your internet connection."
        }
      } catch(e) {
        this.locationValidationMessageTarget.innerHTML = "Server not responding correctly, please check your internet connection."
      }

      this.locationValidationMessageTarget.hidden = false;
      let button = this.scheduleSlotButtonTarget;
      let spinner = button.querySelector(".Spinner");
      spinner.classList.add("u-hidden");
      spinner.classList.remove("u-inlineBlock");
      button.disabled = false;

    }
  }

  // LOGIC FOR HANDLING IF PICK UP POINTS
  async checkZonesForPickUpPointsAndDropOffPoints(locationData) {
    const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
    const school_token = document.getElementById("us-school-token").innerHTML;

    const url = "/us/slots/check-zones/";
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "X-CSRF-Token": csrfToken,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        driving_school_token: school_token,
        pick_up_category: locationData.office_pickup_category,
        drop_off_category: locationData.office_drop_off_category,
        pick_up_address_line_1: locationData.pick_up_address_line_1,
        pick_up_zipcode: locationData.pick_up_zipcode,
        pick_up_school_id: locationData.pick_up_school_id,
        pick_up_point_id: locationData.pick_up_point_id,
        drop_off_address_line_1: locationData.drop_off_address_line_1,
        drop_off_zipcode: locationData.drop_off_zipcode,
        drop_off_school_id: locationData.drop_off_school_id,
        drop_off_point_id: locationData.drop_off_point_id,
      }),
    });

    if (response.ok) {
      const data = await response.json(); // Parse the JSON response

      let data_to_return = {
        pick_up_address_line_1: data.pick_up_point.address,
        pick_up_zipcode: data.pick_up_point.zipcode,
        drop_off_address_line_1: data.drop_off_point.address,
        drop_off_zipcode: data.drop_off_point.zipcode,
        is_address_in_zone: true,
        zone_name: data.pick_up_zone.name,
        zone_color: data.pick_up_zone.color,
        zone_id: data.pick_up_zone.id,
      };

      this.element.dispatchEvent(
        new CustomEvent("addressFound", {
          detail: {
            formName: "driving-school-admin-booking-form-v2-address-details",
            locationData: data_to_return,
          },
        })
      );

      let button = this.scheduleSlotButtonTarget;
      let spinner = button.querySelector(".Spinner");
      spinner.classList.add("u-hidden");
      spinner.classList.remove("u-inlineBlock");
      button.disabled = false;
    } else {
      let button = this.scheduleSlotButtonTarget;
      let spinner = button.querySelector(".Spinner");
      spinner.classList.add("u-hidden");
      spinner.classList.remove("u-inlineBlock");
      button.disabled = false;

      this.locationValidationMessageTarget.innerHTML =
        "Sorry, no instructors are available for the selected location. Please try another location.";
      this.locationValidationMessageTarget.hidden = false;
    }
  }

  validateForm() {
    let validationErrors = [];

    //check if addressOption target is selected
    let addressOption = this.addressOptionTarget;
    if (addressOption.checked) {
      const requiredFields = [
        {
          id: "booking_location_pickup_home_work_address_value",
          name: "Address line 1",
        },
        {
          id: "booking_location_pickup_home_work_zipcode_value",
          name: "Zipcode",
        },
      ];

      requiredFields.forEach((field_data) => {
        let field = document.getElementById(field_data.id);

        if (field) {
          if (field.value.trim() === "") {
            validationErrors.push(`${field_data.name} cannot be empty.`);
          }
          if (
            field_data.id === "zipcode_value" &&
            field.value.trim().length != 5
          ) {
            validationErrors.push(`${field_data.name} must be 5 numbers.`);
          }
        }
      });
    }

    return validationErrors;
  }
}
