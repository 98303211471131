import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "nextButton",
    "nextButton2",
    "nextButton3",
    "nextButton4",
    "nextButton5",
    "bookingForm1",
    "bookingForm2",
    "bookingForm3",
    "bookingForm4",
    "bookingForm5",
    "bookingForm6",
    "dayButton",
    "packageForm",
    "packageOption",
    "timeButton",
    "locationItem",
    "spinner",
  ];

  connect() {
    // find us-booking-next
    const nextButton = document.getElementById("us-booking-next");
    if (nextButton) {
      nextButton.hidden = true;
    }

    // find all elements with ID start with us-booking-next- and hide them
    const nextButtons = document.querySelectorAll('[id^="us-booking-next"]');
    for (var i = 0; i < nextButtons.length; i++) {
      nextButtons[i].hidden = true;
    }

    //find all elements that start with 'progress-' and hide them
    const progressElements = document.querySelectorAll('[id^="progress-"]');
    for (var i = 0; i < progressElements.length; i++) {
      progressElements[i].hidden = true;
    }

    //find all elements that start with 'us-booking-back-' and hide them
    const backButtons = document.querySelectorAll('[id^="us-booking-back"]');
    for (var i = 0; i < backButtons.length; i++) {
      backButtons[i].classList.remove("u-block");
      backButtons[i].hidden = true;
    }
  }

  toggleElements(event, showElementId, hideElementId) {
    event.preventDefault();

    const showElement = document.getElementById(showElementId);
    if (showElement) {
      showElement.hidden = false;
    }

    const hideElement = document.getElementById(hideElementId);
    if (hideElement) {
      hideElement.hidden = true;
    }

    window.scrollTo(0, 0);
  }

  backToExperienceRequirements(event) {
    this.toggleElements(
      event,
      "us-booking-form-admin-experience-requirements",
      "us-booking-form-admin-location"
    );
    this.toggleElements(
      event,
      "customer-service-medical",
      "customer-service-location"
    );
  }

  backToPersonalDetails(event) {
    this.updatePackagesOnDOBChange();
    this.toggleElements(
      event,
      "us-booking-form-1",
      "us-booking-form-admin-experience-requirements"
    );
    this.toggleElements(
      event,
      "customer-service-student-information",
      "customer-service-medical"
    );
    window.scrollTo(0, 0);
  }

  backToLocationAndSlot(event) {
    this.toggleElements(
      event,
      "us-booking-form-admin-pick-up-and-slots",
      "us-booking-form-1"
    );
    this.toggleElements(
      event,
      "customer-service-slots",
      "customer-service-pre-sale"
    );

    const paymentOptions = document.getElementById(
      "us-booking-form-admin-payment-options"
    );
    if (paymentOptions) {
      paymentOptions.hidden = true;
    }

    const freePaymentOptions = document.getElementById(
      "us-booking-form-admin-free-payment-options"
    );
    if (freePaymentOptions) {
      freePaymentOptions.hidden = true;
    }

    const confirmation = document.getElementById(
      "customer-service-confirmation"
    );
    if (confirmation) {
      confirmation.hidden = true;
    }
  }

  backToLocation(event) {
    this.toggleElements(
      event,
      "us-booking-form-admin-location",
      "us-booking-form-admin-pick-up-and-slots"
    );
  }

  nextPaymentOptionsPressedAdmin(event) {
    event.preventDefault();
    const errors = this.validatePickedSlot();

    if (errors.length > 0) {
      this.showErrors(errors);
    } else {
      this.toggleElements(
        event,
        "us-booking-form-admin-payment-options",
        "us-booking-form-admin-pick-up-and-slots"
      );
      this.toggleElements(
        event,
        "customer-service-pre-sale",
        "customer-service-slots"
      );

      const confirmation = document.getElementById(
        "customer-service-confirmation"
      );
      if (confirmation) {
        confirmation.hidden = false;
      }
      const errorMessagesPanel = document.getElementById(
        "error-messages-panel"
      );
      if (errorMessagesPanel) {
        errorMessagesPanel.hidden = true;
      }
    }
  }

  nextSkipPaymentPressedAdmin(event) {
    event.preventDefault();
    const errors = this.validatePickedSlot();

    if (errors.length > 0) {
      this.showErrors(errors);
    } else {
      this.toggleElements(
        event,
        "us-booking-form-admin-free-payment-options",
        "us-booking-form-admin-pick-up-and-slots"
      );
      this.toggleElements(
        event,
        "customer-service-pre-sale",
        "customer-service-slots"
      );

      const confirmation = document.getElementById(
        "customer-service-confirmation"
      );
      if (confirmation) {
        confirmation.hidden = false;
      }
    }
  }

  validatePickedSlot() {
    let validationErrors = [];

    const requiredFields = [{ id: "picked_slot_time", name: "Lesson slot" }];

    requiredFields.forEach((field_data) => {
      let field = document.getElementById(field_data.id);

      if (field) {
        if (field.value.trim() === "") {
          validationErrors.push(`You must pick a lesson slot`);
        }
      }
    });

    return validationErrors;
  }

  showErrors(errors) {
    scrollTo(0, 0);
    const errorMessagesPanel = document.getElementById("error-messages-panel");
    if (errorMessagesPanel) {
      errorMessagesPanel.hidden = false;
      const errorMessagesText = document.getElementById("error-messages");
      errorMessagesText.innerHTML = errors.join("<br>");
    } else {
      alert("Please select a lesson slot.");
    }
  }

  async nextExperienceRequirementsPressed(event) {
    event.preventDefault();

    //create an array of errors
    let errors = [];

    //check field guardian_email and make sure it's either empty or valid email
    const guardianEmail = document.getElementById("guardian_email_value").value;
    if (guardianEmail.trim() != "") {
      if (!guardianEmail.includes("@") || !guardianEmail.includes(".")) {
        errors.push("Please enter a valid email address for the guardian.");
        // alert("Please enter a valid email address for the guardian.");
        // return;
      }
    }

    // check normal email
    const email = document.getElementById("email_value").value;
    if (!email.includes("@") || !email.includes(".")) {
      if (guardianEmail.trim() === "") {
        errors.push("Please enter a valid email address.");
      }
      // alert("Please enter a valid email address.");
      // return;
    } else {
      const encodedEmail = btoa(email);
      try {
        const response = await fetch(`/us/student/${encodedEmail}/taken`);
        const data = await response.json();
        if (data.email_taken === true) {
          errors.push(
            "This email address is already taken. Please use another email address."
          );
          // alert("This email address is already taken. Please use another email address.");
          // return
        }
      } catch (error) {
        errors.push(`Something went wrong when trying to validate the email, ${email}. Please try again.`);
        console.error("Error:", error);
      }
    }

    // make sure guardian email is not the same as student email
    if (guardianEmail.trim() != "" && guardianEmail.trim() === email.trim()) {
      errors.push("Parent/guardian email cannot be the same as student email.");
    }

    // check first name
    const firstName = document.getElementById("first_name_value").value;
    if (firstName.trim() === "") {
      errors.push("Please enter student's first name.");
      // alert("Please enter your first name.");
      // return;
    }

    // check last name
    const lastName = document.getElementById("last_name_value").value;
    if (lastName.trim() === "") {
      errors.push("Please enter student's last name.");
      // alert("Please enter your last name.");
      // return;
    }

    // check phone number
    const phoneNumber = document.getElementById("cell_number_value").value;
    if (phoneNumber.trim() === "") {
      errors.push("Please enter student's phone number.");
      // alert("Please enter your phone number.");
      // return;
    }

    // check permit_number_value
    const permitNumber = document.getElementById("permit_number_value").value;
    if (permitNumber.trim() === "") {
      errors.push(
        "Please enter student's permit number. If international, type in nationality e.g. 'UK License'"
      );
      // alert("Please enter your permit number.");
      // return;
    }

    if (errors.length > 0) {
      scrollTo(0, 0);
      const errorMessagesPanel = document.getElementById(
        "error-messages-panel"
      );
      if (errorMessagesPanel) {
        errorMessagesPanel.hidden = false;
        const errorMessagesText = document.getElementById("error-messages");
        errorMessagesText.innerHTML = errors.join("<br>");
        return;
      }
    }
    const errorMessagesPanel = document.getElementById("error-messages-panel");
    if (errorMessagesPanel) {
      errorMessagesPanel.hidden = true;
    }

    this.updatePackagesOnDOBChange();
    this.toggleElements(
      event,
      "us-booking-form-admin-experience-requirements",
      "us-booking-form-1"
    );
    this.toggleElements(
      event,
      "customer-service-medical",
      "customer-service-student-information"
    );
  }

  nextLocationPressed(event) {
    this.toggleElements(
      event,
      "us-booking-form-admin-location",
      "us-booking-form-admin-experience-requirements"
    );
    this.toggleElements(
      event,
      "customer-service-location",
      "customer-service-medical"
    );
  }

  async nextWithoutSchedulingPressed(event) {
    event.preventDefault();
    const validationMessage = document.getElementById("validation-message-3");
    validationMessage.hidden = true;
    // check zipcode_value and make sure it's 5 numbers and address_line_1_value is not empty
    const zipcode = document.getElementById("zipcode_value").value;
    if (zipcode.trim().length != 5) {
      alert("Zipcode must be 5 numbers.");
      return;
    }
    const addressLine1 = document.getElementById("address_line_1_value").value;
    if (addressLine1.trim() === "") {
      alert("Address line 1 cannot be empty.");
      return;
    }
    const driving_school_token = document
      .getElementById("us-school-token")
      .innerHTML.trim();
    // Replace the URL with the correct endpoint for fetching locations
    const url = `/fetch_locations?zipcode=${zipcode}&address=${addressLine1}&driving_school_token=${driving_school_token}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      },
    });

    if (!response.ok) {
      // Handle error
      validationMessage.innerHTML =
        "The address you entered is not valid or is not covered by this school. Please try again.";
      validationMessage.hidden = false;
      return;
    }

    this.toggleElements(
      event,
      "us-booking-form-admin-payment-options",
      "us-booking-form-admin-location"
    );
    this.toggleElements(
      event,
      "customer-service-location",
      "customer-service-medical"
    );

    // find is_not_scheduling_lessons and set to true
    const isNotSchedulingLessons = document.getElementById(
      "is_not_scheduling_lessons"
    );
    if (isNotSchedulingLessons) {
      isNotSchedulingLessons.value = "true";
    }
  }

  async nextWithoutSchedulingPressedAndAssignFreePackage(event) {
    event.preventDefault();
    const validationMessage = document.getElementById("validation-message-3");
    validationMessage.hidden = true;
    // check zipcode_value and make sure it's 5 numbers and address_line_1_value is not empty
    const zipcode = document.getElementById("zipcode_value").value;
    if (zipcode.trim().length != 5) {
      alert("Zipcode must be 5 numbers.");
      return;
    }
    const addressLine1 = document.getElementById("address_line_1_value").value;
    if (addressLine1.trim() === "") {
      alert("Address line 1 cannot be empty.");
      return;
    }

    const driving_school_token = document
      .getElementById("us-school-token")
      .innerHTML.trim();
    // Replace the URL with the correct endpoint for fetching locations
    const url = `/fetch_locations?zipcode=${zipcode}&address=${addressLine1}&driving_school_token=${driving_school_token}`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      },
    });

    if (!response.ok) {
      // Handle error
      validationMessage.innerHTML =
        "The address you entered is not valid or is not covered by this school. Please try again.";
      validationMessage.hidden = false;
      return;
    }

    this.toggleElements(
      event,
      "us-booking-form-admin-free-payment-options",
      "us-booking-form-admin-location"
    );

    this.toggleElements(
      event,
      "customer-service-location",
      "customer-service-pre-sale"
    );

    // find is_not_scheduling_lessons and set to true
    const isNotSchedulingLessons = document.getElementById(
      "is_not_scheduling_lessons"
    );
    if (isNotSchedulingLessons) {
      isNotSchedulingLessons.value = "true";
    }
  }

  submit(event) {
    event.preventDefault();

    const clickedButton = event.submitter;
    clickedButton.disabled = true;

    const spinnerTarget = document.getElementById("payment-button-spinner");
    const spinnerTargetFree = document.getElementById(
      "payment-button-free-spinner"
    );
    if (clickedButton) {
      switch (clickedButton.id) {
        case "payment-button":
          if (spinnerTarget != undefined) {
            spinnerTarget.classList.remove("u-hidden");
            spinnerTarget.classList.add("u-inlineBlock");
          }
          break;
        case "payment-button-free":
          // find this hidden input field is_free_admin_package and set it to true
          const isFreeAdminPackage = document.getElementById(
            "is_free_admin_package"
          );
          if (isFreeAdminPackage) {
            isFreeAdminPackage.value = "true";
          }
          if (spinnerTargetFree != undefined) {
            spinnerTargetFree.classList.remove("u-hidden");
            spinnerTargetFree.classList.add("u-inlineBlock");
          }
          break;
        default:
          break;
      }
    }

    const formData = new FormData(event.target);
    const url = event.target.action;
    const method = event.target.method;

    fetch(url, {
      method: method,
      body: formData,
      credentials: "same-origin", // Include cookies
      headers: {
        "X-CSRF-Token": getMetaValue("csrf-token"), // Add Rails CSRF token
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw response;
        }
        return response.json();
      })
      .then((data) => {
        // Here you'll handle the server response.
        // This assumes your server responds with JSON that includes the URL.
        if (data.url) {
          window.location.href = data.url;
        }
      })
      .catch((response) => {
        response.json().then((errorData) => {
          if (spinnerTarget != undefined) {
            spinnerTarget.classList.add("u-hidden");
            spinnerTarget.classList.remove("u-inlineBlock");
          }
          if (spinnerTargetFree != undefined) {
            spinnerTargetFree.classList.add("u-hidden");
            spinnerTargetFree.classList.remove("u-inlineBlock");
          }
          clickedButton.disabled = false;
          if (response.status === 400) {
            if (errorData.error === "slot_taken") {
              alert(
                "The slot you selected is no longer available. Please select another slot."
              );
              window.scrollTo(0, 0);
              const paymentButton = document.getElementById("payment-button");
              paymentButton.disabled = false;
            }
          } else {
            const errorMessagesPanel = document.getElementById(
              "error-messages-panel"
            );
            if (errorMessagesPanel) {
              errorMessagesPanel.hidden = false;
              const errorMessagesText =
                document.getElementById("error-messages");
              errorMessagesText.innerHTML = errorData.error.message; // adjust this line if the structure is different
            }
            console.error("Error:", errorData);
          }
        });
      });
  }

  async updatePackagesOnDOBChange() {
    var year = document.getElementById(
      "driving_school_booking_date_of_birth_1i"
    ).value;
    var month = document.getElementById(
      "driving_school_booking_date_of_birth_2i"
    ).value;
    var day = document.getElementById(
      "driving_school_booking_date_of_birth_3i"
    ).value;
    console.log(year + "-" + month + "-" + day);
    const driving_school_token = document
      .getElementById("us-school-token")
      .innerHTML.trim();

    const url = `/schools/us/dashboard/update_packages?date_of_birth=${year}-${month}-${day}&school_token=${driving_school_token}&is_admin=true`;
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      },
    });

    if (response.ok) {
      const stream = await response.text();
      Turbo.renderStreamMessage(stream);
    }
  }

  async next3PressedAdmin(event) {
    event.preventDefault();

    const errors = this.validateForm3();

    const validationMessage = document.getElementById("validation-message-3");

    if (errors.length > 0) {
      validationMessage.innerHTML = errors.join("<br>");
      validationMessage.hidden = false;
    } else {
      validationMessage.hidden = true;

      const address_line_1 = document.getElementById(
        "address_line_1_value"
      ).value;
      const zipcode = document.getElementById("zipcode_value").value;

      document.getElementById(
        "booking_location_pickup_home_work_address_value"
      ).value = address_line_1;
      document.getElementById(
        "booking_location_pickup_home_work_zipcode_value"
      ).value = zipcode;

      document.getElementById(
        "booking_location_drop_off_home_work_address_value"
      ).value = address_line_1;
      document.getElementById(
        "booking_location_drop_off_home_work_zipcode_value"
      ).value = zipcode;

      const driving_school_token = document
        .getElementById("us-school-token")
        .innerHTML.trim();

      // Replace the URL with the correct endpoint for fetching locations
      const url = `/fetch_locations?zipcode=${zipcode}&address=${address_line_1}&driving_school_token=${driving_school_token}`;
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "text/vnd.turbo-stream.html",
        },
      });

      if (response.ok) {
        const stream = await response.text();
        validationMessage.hidden = true;
        Turbo.renderStreamMessage(stream);

        // find us-booking-form-admin-pick-up-and-slots and show it
        const bookingFormAdminPickUpAndSlots = document.getElementById(
          "us-booking-form-admin-pick-up-and-slots"
        );
        if (bookingFormAdminPickUpAndSlots) {
          bookingFormAdminPickUpAndSlots.hidden = false;
        }

        // find us-booking-form-admin-location and hide it
        const bookingFormAdminLocation = document.getElementById(
          "us-booking-form-admin-location"
        );
        if (bookingFormAdminLocation) {
          bookingFormAdminLocation.hidden = true;
        }

        this.toggleElements(
          event,
          "customer-service-slots",
          "customer-service-location"
        );

        // scroll to the top
        window.scrollTo(0, 0);
      } else {
        // Handle error
        validationMessage.innerHTML =
          "The address you entered is not valid or is not covered by this school. Please try again.";
        validationMessage.hidden = false;
      }
    }
  }

  validateForm3() {
    let validationErrors = [];

    const requiredFields = [
      { id: "address_line_1_value", name: "Address line 1" },
      { id: "zipcode_value", name: "Zipcode" },
    ];

    requiredFields.forEach((field_data) => {
      let field = document.getElementById(field_data.id);

      if (field) {
        if (field.value.trim() === "") {
          validationErrors.push(`${field_data.name} cannot be empty.`);
        }
        if (
          field_data.id === "zipcode_value" &&
          field.value.trim().length != 5
        ) {
          validationErrors.push(`${field_data.name} must be 5 numbers.`);
        }
      }
    });

    return validationErrors;
  }
}

// Helper function to get meta value
function getMetaValue(name) {
  const element = document.head.querySelector(`meta[name="${name}"]`);
  return element.getAttribute("content");
}
