import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [
    'address',
    'zipcode',
    'pickUpAddressButton',
    'dropOffAddressButton'
  ];

  connect() {
    console.log("school_lesson_quick_look_edit_location_controller.js")
    this.load_schools_and_pickup_points();
    this.initialize_note_counter("pick_up_note");
    this.initialize_note_counter("drop_off_note");
  }

  async load_schools_and_pickup_points() {
    var lesson_id = document.getElementById("lesson_id");
    let address_line_1 = document.getElementById("booking_location_pickup_home_work_address_value").value;
    const zipcode = document.getElementById("booking_location_pickup_home_work_zipcode_value").value;

    var school_token = document.getElementById("us-school-token").value;
    if (lesson_id) {
      lesson_id = lesson_id.value;
    }

    let extraParams = ""
    if (document.getElementById("pick_up_location_id")) {
      const pickupLocationId = document.getElementById("pick_up_location_id").value;
      const pickUpCategory = document.getElementById("pick_up_category").value;
      const dropoffLocationId = document.getElementById("drop_off_location_id").value;
      const dropOffCategory = document.getElementById("drop_off_category").value;
      extraParams = `&pick_up_location_id=${pickupLocationId}&pick_up_category=${pickUpCategory}&drop_off_location_id=${dropoffLocationId}&drop_off_category=${dropOffCategory}`
      extraParams = encodeURIComponent(extraParams)
    }

    let response = null
    // if zipcode is blank fetch by lesson id
    if (zipcode == "") {
      const url = `/schools/us/fetch-locations/v2/lesson/${lesson_id}/admin?driving_school_token=${school_token}${extraParams}`
      response = await fetch(url, {
        method: "GET",
        headers: {
          "Accept": "text/vnd.turbo-stream.html"
        }
      })

    } else {
      // strip out any # or / from address_line_1
      address_line_1 = address_line_1.replace(/#/g, "")
      address_line_1 = address_line_1.replace(/\//g, "")

      const url = `/schools/us/fetch-locations/v2/admin?driving_school_token=${school_token}&address_line_1=${address_line_1}&zipcode=${zipcode}${extraParams}&lesson_id=${lesson_id}`
      
      response = await fetch(url, {
        method: "GET",
        headers: {
          "Accept": "text/vnd.turbo-stream.html"
        }
      })

    }

    if (response.ok) {
      const stream = await response.text()
      Turbo.renderStreamMessage(stream)
    } else {
      console.log("error")
    }
  }

  previous_drop_off_selected(event) {
    const address = event.currentTarget.getAttribute("data-address")
    const zipcode = event.currentTarget.getAttribute("data-zipcode")

    if (address != "" && zipcode != "") {
      document.getElementById("booking_location_drop_off_home_work_address_value").value = address
      document.getElementById("booking_location_drop_off_home_work_zipcode_value").value = zipcode
      this.booking_drop_off_home_work_clicked(event)
    }

    // get target addressButton and make sure its checked.
    this.dropOffAddressButtonTarget.checked = true

  }

  previous_pick_up_selected(event) {
    const address = event.currentTarget.getAttribute("data-address")
    const zipcode = event.currentTarget.getAttribute("data-zipcode")

    if (address != "" && zipcode != "") {
      document.getElementById("booking_location_pickup_home_work_address_value").value = address
      document.getElementById("booking_location_pickup_home_work_zipcode_value").value = zipcode
      this.booking_pickup_home_work_clicked(event)
    }

    // get target addressButton and make sure its checked.
    this.pickUpAddressButtonTarget.checked = true

  }

  toggle_drop_off_location(event) {
    // check if sameAsPickUpLocation is checked
    if (document.getElementById("sameAsPickUpLocation").checked) {
      document.getElementById("full-drop-off-form").hidden = true;

      const address_line_1 = document.getElementById(
        "address_line_1_value"
      ).value;
      const zipcode = document.getElementById("zipcode_value").value;
      const access_note = document.getElementById("access_note").value;

      document.getElementById(
        "booking_location_drop_off_home_work_address_value"
      ).value = address_line_1;
      document.getElementById(
        "booking_location_drop_off_home_work_zipcode_value"
      ).value = zipcode;
      document.getElementById(
        "drop_off_note"
      ).value = access_note;
    } else {
      document.getElementById("full-drop-off-form").hidden = false;

      document.getElementById(
        "booking_location_drop_off_home_work_address_value"
      ).value = "";
      document.getElementById(
        "booking_location_drop_off_home_work_zipcode_value"
      ).value = "";
      document.getElementById(
        "drop_off_note"
      ).value = "";

      this.initialize_note_counter("drop_off_note");
    }
  }

  toggle_drop_off_location_lesson(event) {
    // check if sameAsPickUpLocation is checked
    if (document.getElementById("sameAsPickUpLocation").checked) {
      document.getElementById("full-drop-off-form").hidden = true;
      document.getElementById("sameAsPickUpLocation").value = "true";
    } else {
      document.getElementById("full-drop-off-form").hidden = false;
      document.getElementById("sameAsPickUpLocation").value = "false";
    }
  }

  booking_pickup_home_work_clicked(event) {
    document.getElementById("booking-location-pickup-home-work").hidden = false;
    document.getElementById("booking-location-pickup-points").hidden = true;
    // document.getElementById("booking-location-pickup-schools").hidden = true; // remove booking-location-pickup-schools
    document.getElementById("booking-location-pickup-previous").hidden = true;
  }

  booking_pickup_points_clicked(event) {
    document.getElementById("booking-location-pickup-home-work").hidden = true;
    document.getElementById("booking-location-pickup-points").hidden = false;
    // document.getElementById("booking-location-pickup-schools").hidden = true;
    document.getElementById("booking-location-pickup-previous").hidden = true;
  }

  booking_pickup_previous_clicked(event) {
    document.getElementById("booking-location-pickup-previous").hidden = false;
    document.getElementById("booking-location-pickup-home-work").hidden = true;
    document.getElementById("booking-location-pickup-points").hidden = true;
  }

  booking_drop_off_home_work_clicked(event) {
    document.getElementById(
      "booking-location-drop-off-home-work"
    ).hidden = false;
    document.getElementById("booking-location-drop-off-points").hidden = true;
    document.getElementById("booking-location-drop-off-previous").hidden = true;
  }

  booking_drop_off_points_clicked(event) {
    document.getElementById(
      "booking-location-drop-off-home-work"
    ).hidden = true;
    document.getElementById("booking-location-drop-off-points").hidden = false;
    document.getElementById("booking-location-drop-off-previous").hidden = true;
  }

  booking_drop_off_previous_clicked(event) {
    document.getElementById("booking-location-drop-off-previous").hidden = false;
    document.getElementById(
      "booking-location-drop-off-home-work"
    ).hidden = true;
    document.getElementById("booking-location-drop-off-points").hidden = true;
  }

  initialize_note_counter(id) {
    const noteField = document.getElementById(id);
    if (!noteField) return;
    
    this.update_note_counter({ target: noteField });
  }

  update_note_counter(event) {
    const MAX_LENGTH = 100;
    const noteLength = event.target.value.length;
    const noteCounterId = event.target.dataset.counterId;
    const noteCounter = document.getElementById(noteCounterId);

    if (noteCounter) {
      noteCounter.textContent = `${noteLength}/${MAX_LENGTH}`;

      if (noteLength > MAX_LENGTH) {
        noteCounter.style.color = "red";
      } else {
        noteCounter.style.color = "";
      }
    }
  }

  validate(event) {
    const errors = this.validate_form();

    if (errors.length > 0) {
      event.preventDefault();
      this.show_validation_block(errors.join("<br>"));
      return;
    }    
  }

  validate_form() {
    let validationErrors = [];

    let fieldsToValidate = [{ id: "pick_up_note", name: "Access note for pick-up location" }];

    if (!document.getElementById("sameAsPickUpLocation").checked) {
      fieldsToValidate.push({ id: "drop_off_note", name: "Access note for drop-off location" });
    }

    fieldsToValidate.forEach(field_data => {
      let field = document.getElementById(field_data.id);
      const MAX_LENGTH = 100;

      if (field) {
        if (field.value.trim().length > MAX_LENGTH) {
          validationErrors.push(`${field_data.name} must be ${MAX_LENGTH} characters or less.`);
        }
      }
    });
    
    return validationErrors;
  }

  show_validation_block(message) {
    const validationBlock = document.getElementById("validation-block");
    const validationMessage = document.getElementById("validation-message");
  
    validationMessage.innerHTML = message;
    validationBlock.hidden = false;
    validationMessage.hidden = false;
  } 
}